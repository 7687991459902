import React, { Component } from 'react';
import TemplateLogin from './../components/Login';
import 'antd/dist/antd.css';
import API_SERVICE from './../services/api'
import { Input, Button, message } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

declare var window: any;
var RECAPTCHA_KEY = window.config.key_Recaptcha;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const getExeception = (err:any) => {
    if(err.errors){
        const values = Object.entries(err.errors);
        //const getMessages: Array<string> = [];
        values.map((item:any) => message.error(item[1][0]))
        //return getMessages;
    } else {
        message.error(err);
    }
}

class Login extends Component<any, any> {

	constructor(props: any) {	
		super(props);
		this.state = {
			user: {
				email: '',
				password: '',
				recaptcha: ''
			},
			msgEmail: false,
			msgPassword: false,
			loading: false,
			active:false,
			messageError: null
		};
	}

	async componentDidMount() {
		this.loginWithHash();
		//const token = await this.props.googleReCaptchaProps.executeRecaptcha(Login);
	}

	/* login with hash */
	loginWithHash = () => {
		//console.log(window.location.pathname);
		if(window.location.pathname !== '/qraccess') return;

		const location = window.location.search;
		const urlParams = new URLSearchParams(location);
		const hash:string = `${urlParams.get('hash')}`;

		API_SERVICE.Cuenta_Login_Hash(hash)
		.then((res) => {
			const { status, data } = res;

			if(status === 200) {
				//nombre - user
				localStorage.setItem('token', data.result.token);
				localStorage.setItem('user', JSON.stringify({
					nombre: data?.result.nombre, 
					apellido: data?.result.apellidoPaterno,
					apellidoM: data?.result.apellidoMaterno,
					email: data?.result.email,
					tipo: data?.result.tipo
				}));
				localStorage.setItem('dashboard', JSON.stringify(data.result.dashboard));
				localStorage.setItem('menu', JSON.stringify(data.result.menuLateral));

				window.location.href = `/${data.result.urlRedirect}`; 
			}
		})
		.catch((err) => {
			getExeception(err);
		})
	}

	onChange = (payload:any) => {
		const { name, value, msg } = payload;

		this.setState((state:any) =>({
			...state, user: {
				...state.user,
				[name]: value
			},
			[msg]: true
		}))
	}

	onSubmit = (e:any) => {
		e.preventDefault();

		const enter = (document.all) ? e.keyCode : e.which;
        if (enter != 13){
			let token = ""; 
			this.props.googleReCaptchaProps.executeRecaptcha(Login).then((token:any) => token);

			this.setState((state:any) => ({
					...state, loading:true, user:{
						...state.user,
						recaptcha: token
					},
				}) 
			);
			const validation = this.validation();

			if(validation.length > 0) {
				this.setState({msgEmail: true, msgPassword: true, loading:false, messageError:null})
				return;
			}

			API_SERVICE.Cuenta_Login(this.state.user)
			.then((res:any) => {
				const { status, data } = res;

				if(status === 200) {
					//nombre - user
					localStorage.setItem('token', data.result.token);
					localStorage.setItem('user', JSON.stringify({
						nombre: data.result.nombre, 
						apellido: data.result.apellidoPaterno,
						apellidoM: data.result.apellidoMaterno,
						email: data.result.email,
						tipo: data.result.tipo
					}));
					localStorage.setItem('dashboard', JSON.stringify(data.result.dashboard));
					localStorage.setItem('menu', JSON.stringify(data.result.menuLateral));

					window.location.href = `/${data.result.urlRedirect}`; 
					this.setState({loading:false, active:false, messageError: null})
				}
			})
			.catch((err) => {
				this.setState({
					loading: false,
					active: false
				})
				const msg = err.response.data.responseException.exceptionMessage;
				if(msg.errors){
					this.setState({messageError: msg.errors.Email[0]});
				}
				else {
					this.setState({messageError: msg})
				}
			})
		}
	}

	validation = () => {
		const { email, password } = this.state.user;
		const data = [];
		if( email === undefined || email === null || email === '') data.push('Correo');
		if( password === undefined || password === null || password === '' ) data.push('Contraseña')

		return data;
	}

	render() {
		const { email, password } = this.state.user;
		const { msgEmail, msgPassword, messageError } = this.state;

		return (
			<TemplateLogin>
				<div>
					<form onSubmit={this.onSubmit}>
						<div className="header">
							<label className="title">Iniciar Sesión</label>
							<p className="info">Por favor, ingresa tu correo y contraseña</p>
						</div>
						<div className="message">
								{ messageError &&  <Spin spinning={this.state.loading}><div className="msgError"> <a>x</a><label>{messageError}</label> </div> </Spin>}
						</div>
						<div className="body">
							<div className="block-input">
								<label>CORREO:</label>
								<Input
									type="email"
									placeholder="Correo" 
									value={ email } 
									onChange={ (e:any) => this.onChange({ name: 'email', value: e.target.value, msg:'msgEmail' }) } 
								/>
								{ msgEmail && !email && <p>Ingrese un correo</p> }
							</div>
							<div className="block-input">
								<label>CONTRASEÑA:</label>
								<Input.Password
									type="password" 
									placeholder="Contraseña" 
									value={ password }
									iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
									onChange={ (e:any) => this.onChange({ name: 'password', value: e.target.value, msg:'msgPassword' } ) }
								/>
								{ msgPassword && !password && <p>Ingrese una contraseña</p> }
							</div>

							<a href="/forgot-Password">¿Olvidaste tu contraseña?</a>
						</div>
						<div className="submit">
							<Spin indicator={antIcon} spinning={this.state.loading} size="small">
									<button> Ingresar </button>
								{/*<button onClick={() => this.onSubmit()}> Ingresar </button>*/}
							</Spin>
						</div>
					</form>
				</div>
			</TemplateLogin>
		)
	}
}

const YourReCaptchaComponent = withGoogleReCaptcha(Login);
 
const LoginCaptcha = () => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={ RECAPTCHA_KEY }>
			<YourReCaptchaComponent />
		</GoogleReCaptchaProvider>
	)
}

export default LoginCaptcha;