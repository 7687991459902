import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";
import { Provider } from "react-redux";
import './index.css';
//import { runInitialJobs } from "./config/initial";
import 'antd/dist/antd.css';

//runInitialJobs();

ReactDOM.render(
	//<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>, 	
	//</React.StrictMode>,
	document.getElementById("root")
);

serviceWorker.unregister();