import { takeLatest, call, put } from "redux-saga/effects";
import ActionType from "../constants";
import API_SERVICE from "../../services/api";
import * as Action from "../actions";

function* ECuenta_Login(action:any):any{
    try
    {
        const data = (yield call(API_SERVICE.Cuenta_Login, action.payload)).data.result;
        yield put(Action.ACuenta_Login_Add(data));
        action.resolve();
    }
    catch(err)
    {
        action.reject(err);
    }
}

function* ECuenta_Cambia_Password(action:any):any{
    try {
        const data = (yield call(API_SERVICE.Cuenta_Cambia_Password, action.payload));
        yield put(Action.ACuenta_Cambia_Password_Add(data));
        action.resolve();
    }
    catch(error){
        const err:any = error; 
        action.reject(err?.response.data.responseException.exceptionMessage);
    }
}

function* ECuenta_Tablero_Control():any{

    const data = (yield call(API_SERVICE.Cuenta_Tablero_Control, {})).data.result;
    yield put(Action.ACuenta_Tablero_Control_Add(data));
}

function* ECuenta_Menu_Lateral():any{
    const data =  (yield call(API_SERVICE.Cuenta_Menu_Lateral, {})).data.result;
    yield put(Action.ACuenta_Menu_Lateral_Add(data));
}


function* ECuenta_agrega_Usuario(action:any):any{
    try{
        const res = (yield call(API_SERVICE.Cuenta_Agrega_Usuario, action.payload));

        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario)).data.result;
        yield put(Action.ACuenta_Lista_Usuario_Add(List));

        action.resolve(res.data.message);
    }
    catch(error){
        const err:any = error; 
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* ECuenta_Actualiza_Usuario(action:any):any{
    try{
        const res = yield call(API_SERVICE.Cuenta_Actualiza_Usuario, action.payload)
        
        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario)).data.result;
        yield put(Action.ACuenta_Lista_Usuario_Add(List));

        action.resolve(res.data.message);
    }
    catch(error){
        const err:any = error; 
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* ECuenta_Elimina_Usuario(action:any):any{
    try{
        const res = yield call(API_SERVICE.Cuenta_Elimina_Usuario, action.payload);
        
        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario)).data.result;
        yield put(Action.ACuenta_Lista_Usuario_Add(List));

        action.resolve(res.data.message);
    }
    catch(error){
        const err:any = error; 
        action.reject(err.response.data.responseException.exceptionMessage);
    }
} 

function* ECuenta_Lista_Usuario(action:any):any{
    try{
        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario)).data.result;
        yield put(Action.ACuenta_Lista_Usuario_Add(List));
        action.resolve();
    }
    catch(error){
        const err:any = error; 
        action.reject();
    }
}

function* ECuenta_Selecciona_Usuario(action:any):any{
    try{
        const OnlyOne = (yield call (API_SERVICE.Cuenta_Selecciona_Usuario, action.payload)).data.result;
        yield put(Action.ACuenta_Selecciona_Usuario_Add(OnlyOne));
        action.resolve();
    }
    catch(error){
        const err:any = error; 
        action.reject();
    }
}



function* ECuenta_agrega_Usuario_Stakeholder(action:any):any{
    try{
        const res = (yield call(API_SERVICE.Cuenta_Agrega_Usuario_stakeholder, action.payload.id, action.payload.data));

        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario_stakeholder, {})).data.result;
        templateListUsuarioStake(List);
        yield put(Action.ACuenta_Lista_Usuario_Stakeholder_Add(List));
        action.resolve(res.data.message);
    }
    catch(error){
        const err:any = error; 
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* ECuenta_Actualiza_Usuario_Stakeholder(action:any):any{
    try{
        const res = (yield call(API_SERVICE.Cuenta_Actualiza_Usuario_stakeholder, action.payload.id, action.payload.data));

        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario_stakeholder, {})).data.result;
        templateListUsuarioStake(List);
        yield put(Action.ACuenta_Lista_Usuario_Stakeholder_Add(List));
        action.resolve(res.data.message);
    }
    catch(error){
        const err:any = error; 
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* ECuenta_Elimina_Usuario_Stakeholder(action:any):any{
    try{
        const res = yield call(API_SERVICE.Cuenta_Elimina_Usuario_stakeholder, action.payload);
        
        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario_stakeholder, {})).data.result;
        templateListUsuarioStake(List);
        yield put(Action.ACuenta_Lista_Usuario_Stakeholder_Add(List));

        action.resolve(res.data.message);
    }
    catch(error){
        const err:any = error; 
        action.reject(err.response.data.responseException.exceptionMessage);
    }
} 

function* ECuenta_Lista_Usuario_Stakeholder(action:any):any{
    try{

        const { payload: { search, data } } = action;
        console.log(action.payload);
        
        if(!search){
            const listStakeholder = (yield call (API_SERVICE.Stakeholder_lista_stakeholder2)).data.result;
            const List_Type = (yield call (API_SERVICE.Stakeholder_lista_tipo_stakeholder, {})).data.result;
            const arrayData = Object.entries(listStakeholder);
            const newListStake: Array<object> = [];

            arrayData.map((item:any) => {
                newListStake.push({
                    label: item[1],
                    value: parseInt(item[0])
                })
            });

            yield put(Action.ACuenta_Lista_Stakeholder_Add(newListStake));
            yield put(Action.AStakeholder_Lista_Tipo_StakeHolder_Add(List_Type));
        }

        const List = (yield call (API_SERVICE.Cuenta_Lista_Usuario_stakeholder, data)).data.result;
        templateListUsuarioStake(List);
        yield put(Action.ACuenta_Lista_Usuario_Stakeholder_Add(List));
        action.resolve();
    }
    catch(error){
        const err:any = error; 
        action.reject();
    }
}

function* ECuenta_Selecciona_Usuario_Stakeholder(action:any):any{
    try{
        const OnlyOne = (yield call (API_SERVICE.Cuenta_Selecciona_Usuario_stakeholder, action.payload)).data.result;
        yield put(Action.ACuenta_Selecciona_Usuario_Stakeholder_Add(OnlyOne));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* ECuenta_Descarga_Qr(action:any):any{
    try {
        const image = (yield call(API_SERVICE.Cuenta_Descarga_Qr, action.payload)).data.result;
        action.resolve(image);
    } catch(error){
        const err:any = error;
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* ECuenta_Descarga_Qr_Zip(action:any):any{
    try {
        const zip = (yield call(API_SERVICE.Cuenta_Descarga_Qr_Zip, action.payload)).data.result;
        action.resolve(zip);
    } catch(error){
        const err:any = error;
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

export function* WCuenta_agrega_Usuario():any{ return yield takeLatest(ActionType.CUENTA_AGREGA_USUARIO_CALL, ECuenta_agrega_Usuario)};
export function* WCuenta_Actualiza_Usuario():any{ return yield takeLatest(ActionType.CUENTA_ACTUALIZA_USUARIO_CALL, ECuenta_Actualiza_Usuario)};
export function* WCuenta_Elimina_Usuario():any{ return yield takeLatest(ActionType.CUENTA_ELIMINA_USUARIO_CALL, ECuenta_Elimina_Usuario)};
export function* WCuenta_Lista_Usuario():any{ return yield takeLatest(ActionType.CUENTA_LISTA_USUARIO_CALL, ECuenta_Lista_Usuario)};
export function* WCuenta_Selecciona_Usuario():any{ return yield takeLatest(ActionType.CUENTA_SELECCIONA_USUARIO_CALL, ECuenta_Selecciona_Usuario)};

export function* WCuenta_agrega_Usuario_Stakeholder():any{ return yield takeLatest(ActionType.CUENTA_AGREGA_USUARIO_STAKEHOLDER_CALL, ECuenta_agrega_Usuario_Stakeholder)};
export function* WCuenta_Actualiza_Usuario_Stakeholder():any{ return yield takeLatest(ActionType.CUENTA_ACTUALIZA_USUARIO_STAKEHOLDER_CALL, ECuenta_Actualiza_Usuario_Stakeholder)};
export function* WCuenta_Elimina_Usuario_Stakeholder():any{ return yield takeLatest(ActionType.CUENTA_ELIMINA_USUARIO_STAKEHOLDER_CALL, ECuenta_Elimina_Usuario_Stakeholder)};
export function* WCuenta_Lista_Usuario_Stakeholder():any{ return yield takeLatest(ActionType.CUENTA_LISTA_USUARIO_STAKEHOLDER_CALL, ECuenta_Lista_Usuario_Stakeholder)};
export function* WCuenta_Selecciona_Usuario_Stakeholder():any{ return yield takeLatest(ActionType.CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_CALL, ECuenta_Selecciona_Usuario_Stakeholder)};
export function* WCuenta_Descarga_Qr():any{ return yield takeLatest(ActionType.CUENTA_DESCARGA_QR, ECuenta_Descarga_Qr)};
export function* WCuenta_Descarga_Qr_Zip():any{ return yield takeLatest(ActionType.CUENTA_DESCARGA_QR_ZIP, ECuenta_Descarga_Qr_Zip)};

export function* WCuenta_Login():any{ return yield takeLatest(ActionType.CUENTA_LOGIN_CALL, ECuenta_Login)};
export function* WCuenta_Cambia_Password():any{ return yield takeLatest(ActionType.CUENTA_CAMBIA_PASSWORD_CALL, ECuenta_Cambia_Password)};
export function* WCuenta_Tablero_Control():any{ return yield takeLatest(ActionType.CUENTA_CONTROL_TABLERO_CALL, ECuenta_Tablero_Control)};
export function* WCuenta_Menu_Lateral():any{ return yield takeLatest(ActionType.CUENTA_MENU_LATERAL_CALL, ECuenta_Menu_Lateral) };

const templateListUsuarioStake = (List:Array<object>) => {

    List.map((item:any, index:number) => {
        switch(item.estado){
            case "P": item.estado = "Pendiente"; break;
            case "A": item.estado = "Activo"; break;
            case "I": item.estado = "Inactivo"; break;
            default: item.estado = item.estado; break;
        }
    })

    orderById(List);
    
}

const orderById = (Array:any) => {
    const newArray = Array.sort((a:any, b:any) => b.id - a.id);
    return newArray; 
}