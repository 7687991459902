import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute } from '../components/privateRouter';
import ReactLoading from "react-loading";
import ErrorBoundary from "./ErrorBoundary";
import Login from "../pages/Login";
import LogoPowered from './../assets/images/logo/Powered-MagicChain.svg';

const LoginQR = lazy(() => import('./../pages/LoginQr'));
const ForgotPassword =  lazy(() => import('../pages/ForgotPassword'));
const RecoverPassword = lazy(() => import('../pages/RecoverPassword'));
const Dashboard = lazy(() => import('./Route_Dashboard'));
const EmailActivaction = lazy(() => import('../pages/emailActivation'))

export class Main extends React.Component<any, any> {

	render() {
		return (
			<ErrorBoundary>
				<Suspense fallback={<ReactLoading type={"spin"} color={"#000"} height={"45px"} width={"45px"} className={"loading__router"} />}>
					<>
						<Switch>
							<Route exact path="/login" component={Login} />
							<Route exact path="/qraccess" component={LoginQR} />
							<Route exact path="/forgot-Password" component={ForgotPassword} />
							<Route exact path="/recuperar-password" component={RecoverPassword} />
							<Route exact path="/activar-cuenta" component={ EmailActivaction } />
							<PrivateRoute path='/tablero-control' component={ Dashboard } />
							<Redirect path='/' to='/tablero-control' />
						</Switch>
						<div className='logoPowered'> <a target="_blank" href="https://magia.digital/magicchain"> <img src={LogoPowered} height={40}/> </a> </div>
					</>
				</Suspense>
			</ErrorBoundary>	
		)
	}
}

export default Main;